import React, { useEffect, useCallback, useState } from "react";
import trackPathForAnalytics from "../TrackPathForAnalytics";

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    console.log("windows phone");
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    console.log("android");
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    console.log("iOS");
    return "iOS";
  }

  return "unknown";
}
const endpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/editorapi/redirectFrom"
    : "https://app.10point.ai/editorapi/redirectFrom";
async function postData(url = endpoint, data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const Math10 = (props) => {
  const { pathname, search } = window.location;
  const [device, setDevice] = useState("");

  const redirectSomewhere = (where) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const teacher = urlParams.get("tch");
    if (!teacher) {
      window.location.href = where;
    } else {
      postData(endpoint, { tch: teacher })
        .then((res) => {
          window.location.href = where
          // console.log("added");
        })
        .catch((err) => {
          window.location.href = where
          // alert(err);
        });
    }
  };

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: "/" + getMobileOperatingSystem(),
      search: search,
      title: pathname.split("/")[1],
    });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  useEffect(() => {
    const os = getMobileOperatingSystem();
    if (os == "Android") {
      setDevice("android");
      // window.location.href = "market://details?id=com.tenpoint.ai";
      redirectSomewhere("market://details?id=com.tenpoint.ai");
    } else {
      setDevice("other");
      // window.location.href = "https://app.10point.ai";
      redirectSomewhere("https://app.10point.ai");
    }
  }, []);

  return <>Loading...{props.text || 'main Page'}</>;
};

export default Math10;
